import React from 'react';

import Layout from '../components/Layout';

const ListenPage = () => (
  <Layout fullMenu>
    <article id="main">
      <header>
        <h2>Listen</h2>
      </header>
      <section className="wrapper style5">
        <div className="inner">
          <h3>Videos</h3>
          <div className="video-grid">
            <iframe
              src="https://www.youtube.com/embed/-ON4I3c-XyE"
              frameBorder="0"
              title="Bach A Minor Grave"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
            <iframe
              src="https://www.youtube.com/embed/V-fUTeCSz_U"
              frameBorder="0"
              title="Berg Violin Concerto"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
            <iframe
              src="https://www.youtube.com/embed/nukJeGIUunY"
              frameBorder="0"
              title="Brahms Sonata No 2"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
            <iframe
              src="https://www.youtube.com/embed/r37gZTXPdaM"
              frameBorder="0"
              title="George Crumb - 'Nocturnes'"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
            <iframe
              src="https://www.youtube.com/embed/q_dglINP8yg"
              frameBorder="0"
              title="Christopher Theofanidis - 'Five'"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
            <iframe
              src="https://www.youtube.com/embed/QT2Ace2-4fA"
              frameBorder="0"
              title="Zimmermann Solo Violin Sonata"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
        </div>
      </section>
    </article>
  </Layout>
);

export default ListenPage;
